<template>
  <div>
    <main-nav />
    <Sidebar />
    <div class="app-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS
import io from "socket.io-client";
import Sidebar from "@/components/Layouts/Sidebar.vue";

import MainNav from "../components/Layouts/MainNav.vue";

export default {
  name: "Home",

  components: { Sidebar, MainNav },

  data() {
    return {};
  },

  computed: {
    // START:: VUEX GET AUTHENTICATED USER DATA
    ...mapGetters("auth_module", ["currentUser"]),
    // END:: VUEX GET AUTHENTICATED USER DATA
    chats() {
      return this.$store.getters["chat_module/chats"];
    },
    user() {
      return this.$store.getters["auth_module/currentUser"];
    },
  },

  methods: {
    launch_resize_dispatch() {
      this.$store.dispatch("sideNav_module/onResize");
    },
    allSoket() {
      const _self = this;
      var socket = io(process.env.VUE_APP_BASE_SOCKET_URL);

      // socket.on(`NewSupportChat`, (payload) => {
      //   // console.log(payload);
      //   if (payload) {
      //     let found = this.chats.find((el) => el.id == payload.data.id);
      //     if (found) {
      //       found.last_message = payload.data.message;
      //       found.last_message_created_at = payload.data.ago_time;
      //       found.last_message_created = new Date(
      //         payload.data.created_at
      //       ).getTime();
      //       found["is_read"] = payload.data.is_read;
      //     }

      //     this.$iziToast.show({
      //       balloon: true,
      //       displayMode: 2,
      //       timeout: 15000,
      //       title: "لديك رسالة جديده ",
      //     });
      //     var audio = document.createElement("AUDIO");
      //     document.body.appendChild(audio);
      //     // var audio = new Audio();
      //     audio.src = require("@/assets/media/sounds/notification.mp3");
      //     audio.play();
      //     // new Audio(require("@/assets/media/sounds/notification.mp3")).play();
      //   }
      // });

      socket.on(
        `AdminNotification:${localStorage.getItem("Bro_Company_Dash_User_Id")}`,
        (payload) => {
          if (payload) {
            var audio = document.createElement("AUDIO");
            document.body.appendChild(audio);
            if (payload.notify_type == "unusual_behaviour") {
              audio.src = require("@/assets/media/sounds/complaint.wav");
              audio.play();
              this.$swal({
                title: payload.title[this.$i18n.locale],
                text: payload.body[this.$i18n.locale],
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: this.$t("cancel"),
                confirmButtonText: this.$t("show"),
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router
                    .push(`/trips/show/${payload.trip_id}`)
                    .catch(() => {});
                }
              });
            } else {
              // var audio = new Audio();
              audio.src = require("@/assets/media/sounds/notification.wav");
              audio.play();
            }

            let dataToPush = {
              data_id: payload.data_id,
              body: payload.body,
              title: payload.title,
            };

            _self.$store.commit(
              "notifications_module/pushNotification",
              dataToPush
            );

            // console.log(payload);
            this.$iziToast.show({
              title: payload.title[this.$i18n.locale],
              message: payload.body[[this.$i18n.locale]],
            });
          }
        }
      );
    },
    getPermissions() {
      this.axios
        .get("get_my_permissions")
        .then((res) =>
          localStorage.setItem("bro_permissions", JSON.stringify(res.data.data))
        );
    },
    getControls() {
      this.axios
        .get("get_my_control_permissions")
        .then((res) =>
          localStorage.setItem("bro_controls", JSON.stringify(res.data.data))
        );
    },
  },

  mounted() {
    // this.$store.dispatch("chat_module/getChats");
    this.$store.dispatch("notifications_module/getNotifications");
    this.allSoket();
  },
  created() {
    if (this.user.usertype != "company") {
      this.getPermissions();
      this.getControls();
    } else {
      if (localStorage.getItem("bro_controls")) {
        localStorage.removeItem("bro_controls");
      }
      if (localStorage.getItem("bro_permissions")) {
        localStorage.removeItem("bro_permissions");
      }
    }
  },
};
</script>
