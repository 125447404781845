<template>
  <div class="countries_all">
    <the-table
      :headers="headers"
      tableName="contacts"
      @expandFired="mapItem"
      :showFilter="false"
      :actions="{ show: true }"
    >
      <template v-slot:expand>
        <div v-if="expandData">
          <ul>
            <li v-for="(value, key) of expandData" :key="key" class="replais">
              <span>
                <span class="key">
                  {{ value.sender.first_name }} {{ value.sender.last_name }}
                </span>
                <br />
                <span class="value" v-html="value.reply"></span>
                <span class="date"> {{ value.created_at }} </span>
              </span>
            </li>
          </ul>
        </div>
        <NoFound class="mt-5" v-else text="لا يوجد ردود"></NoFound>
      </template>
    </the-table>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      // ========== headers
      headers: [
        {
          text: this.$t("labels.image"),
          value: "user.avatar",
          align: "center",
        },
        {
          text: this.$t("labels.name"),
          value: "fullnameContact",
          align: "center",
        },
        {
          text: this.$t("labels.type"),
          value: "typeContact",
          align: "center",
        },
        {
          text: this.$t("labels.email"),
          value: "emailContact",
          align: "center",
        },
        {
          text: this.$t("labels.phone"),
          value: "phoneContact",
          align: "center",
        },
        {
          text: this.$t("labels.created_at"),
          value: "created_at",
          align: "center",
        },
        {
          text: "نص الرساله",
          value: "contentContact",
          align: "center",
        },
        {
          text: "تاريخ القراءه",
          value: "read_at",
          align: "center",
        },

        {
          text: "الردود",
          value: "extendIcon",
          align: "center",
          sortable: false,
        },
        {
          text: "ارسل رد",
          value: "sendReply",
          align: "center",
          sortable: false,
        },
      ],

      expandData: null,
    };
  },

  methods: {
    ...mapMutations({
      set_breadcrumb: "set_breadcrumb",
    }),

    mapItem(data) {
      this.expandData = data.replies;
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.set_breadcrumb({
      title: this.$t("breadcrumb.contacts.title"),
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.contacts.title"),
          disabled: false,
          href: "/contacts",
        },
        {
          text: this.$t("breadcrumb.contacts.all"),
          disabled: true,
          href: "",
        },
      ],
    });
  },
};
</script>
