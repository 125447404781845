<template>
  <div>
    <v-switch
      :disabled="loading || disabled"
      :loading="loading"
      :color="type == 'active' ? '#26BE00' : '#ed143d'"
      v-model="item[value]"
      @mousedown="confirmSwith(item, value, $event)"
    ></v-switch>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      required: false,
    },
    name: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // ===== Delete
    confirmSwith(item, value, event) {
      event.preventDefault();
      // Start:: Sweet Alert  Modal
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("youWillNotBeAbleToGoBackInTheProcess"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.SwitchToggle(item, value);
        }
      });
      // End:: Sweet Alert  Modal
    },

    SwitchToggle(item, value) {
      this.loading = true;
      let name = this.name;
      const frmData = new FormData();
      frmData.append("_method", "PATCH");
      // is_admin_active_user

      let message = "";

      if (name == "locked") {
        message =
          item.is_locked == 1
            ? this.$t("messages.locked")
            : this.$t("messages.unLocked");
      } else if (name == "accept") {
        message = item.company_accept
          ? this.$t("messages.accept")
          : this.$t("messages.unAccept");
      } else if (name == "activate") {
        message =
          item.company_active == 1
            ? this.$t("messages.activate")
            : this.$t("messages.noActivate");
      } else if (name == "ban") {
        message = item.is_ban
          ? this.$t("messages.ban")
          : this.$t("messages.unBan");
      }

      this.axios
        .post(`partners/${name}/${item.id}`, frmData)
        .then(() => {
          item[value] = +item[value] == 1 ? 0 : 1;
          this.loading = false;
          this.$iziToast.success({
            title: this.$t("success"),
            message: message,
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
  },
};
</script>

<style></style>
