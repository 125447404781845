<template>
  <div class="row vehicle_show" v-if="vehiclesData">
    <div class="col-12">
      <div class="vehicles_profile_card">
        <div class="chart_progress">
          <h3 class="car_name d-flex justify-content-between">
            <span>
              {{ $t("labels.vechile_type") }} :

              {{ vehiclesData.vehicle_model_id.vehicle_brand.name }}</span
            >
            <strong>
              {{ $t("labels.status") }} :
              <span
                class="status"
                :class="
                  vehiclesData.status == 'according' ? 'active' : 'inactive'
                "
              >
                {{ $t(vehiclesData.status) }}</span
              >
            </strong>
          </h3>
          <div class="d-flex flex-wrap">
            <div class="user_status">
              <span class="active"> {{ $t("labels.active_status") }} :</span>
              <span>
                <v-switch
                  class="p-0"
                  :color="vehiclesData.is_active ? '#26BE00' : '#FF0000'"
                  v-model="vehiclesData.is_active"
                  @change="changeActiveStatus"
                ></v-switch
              ></span>
            </div>
            <div class="user_status">
              <span class="active"
                >{{ $t("labels.vehicle_is_in_order") }} :</span
              >
              <span>
                <v-switch
                  color="#26BE00"
                  @change="changeTripStatus"
                  v-model="vehiclesData.is_in_order"
                ></v-switch
              ></span>
            </div>
            <div class="user_status">
              <span class="inactive"
                >{{ $t("labels.lock", { name: $t("labels.vehicle") }) }} :
              </span>
              <span>
                <v-switch
                  @change="changeLockStatus"
                  color="#FF0000"
                  v-model="vehiclesData.is_locked"
                ></v-switch
              ></span>
            </div>
          </div>

          <div class="vehicle_image">
            <img
              @click="show_model_1(vehiclesData.image)"
              @error="imageError"
              :src="vehiclesData.image"
              :alt="vehiclesData.name"
            />

            <!-- <img :src="vehiclesData.vehicle_image" :alt="vehiclesData.name" /> -->
          </div>
        </div>

        <div class="vehicle_info">
          <!-- Start:: Map -->
          <div class="map" id="mapCanvas" width="100%"></div>
          <!-- Start:: Map -->
          <ul>
            <li>
              <!-- <span class="icon"><i class="fas fa-clock"></i></span> -->
              {{ $t("labels.manufacturing_year") }} :
              <p v-if="vehiclesData.manufacturing_year != null">
                {{ vehiclesData.manufacturing_year }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
            <li>
              <!-- <span class="icon"><i class="fas fa-car"></i></span> -->
              {{ $t("labels.vechile_type") }} :
              <p v-if="vehiclesData.vehicle_model_id">
                {{ vehiclesData.vehicle_model_id.vehicle_brand.name }}
              </p>
              <p v-else class="redColor fontBold"></p>
            </li>
            <li>
              <!-- <span  class="icon"><i class="fas fa-quote-left"></i></span> -->
              {{ $t("labels.vehicle_model") }} :
              <p v-if="vehiclesData.vehicle_model_id">
                {{ vehiclesData.vehicle_model_id.name }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
            <li>
              <!-- <span class="icon"><i class="fas fa-text-height"></i></span> -->
              {{ $t("labels.vehicle_package") }} :
              <p v-if="vehiclesData.vehicle_package_id">
                {{ vehiclesData.vehicle_package_id.title }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
            <li>
              <!-- <span class="icon"><i class="fas fa-eye-dropper"></i></span> -->
              {{ $t("labels.vehicle_color") }} :
              <p v-if="vehiclesData.color">{{ vehiclesData.color }}</p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
            <li>
              <!-- <span class="icon"><i class="fas fa-list-ol"></i></span> -->
              {{ $t("labels.plate_number") }} :
              <p v-if="vehiclesData.plate_number">
                {{ vehiclesData.plate_number }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
            <li>
              {{ $t("labels.seats_number") }} :
              <p v-if="vehiclesData.seats_number">
                {{ vehiclesData.seats_number }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
            <li>
              <!-- <span class="icon"><i class="fas fa-list-ol"></i></span> -->
              {{ $t("labels.license_number") }} :
              <p v-if="vehiclesData.license_number">
                {{ vehiclesData.license_number }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>

            <li>
              {{ $t("labels.created_at") }} :
              <p v-if="vehiclesData.created_at != ''">
                {{ vehiclesData.created_at }}
              </p>
              <p v-else class="redColor fontBold">{{ $t("notFound") }}</p>
            </li>
          </ul>
        </div>
        <!-- Start Image_Model -->
        <ImageModal
          :modalIsOpen="model_1.show_model"
          :modalImage="model_1.model_img_src"
          @toggleModal="model_1.show_model = false"
        />
        <!-- End Image_Model -->
      </div>
    </div>

    <base-model :show="showActive" @close="showActive = false">
      <div class="notificationPopup customCard text-center">
        <h3 class="h_3" v-if="vehiclesData.is_admin_accept">
          الغاء تفعيل العربة
        </h3>
        <h3 class="h_3" v-else>تفعيل العربة</h3>

        <div class="mt-5">
          <v-btn
            v-if="!vehiclesData.is_admin_accept"
            @click="toggleActive"
            class="mx-2"
            fab
            dark
            small
            color="green"
          >
            <v-icon dark>fas fa-check</v-icon>
          </v-btn>

          <v-btn
            v-else
            @click="toggleActive"
            class="mx-2"
            fab
            dark
            small
            color="red"
          >
            <v-icon dark>fas fa-times</v-icon>
          </v-btn>
        </div>
      </div>
    </base-model>
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBNLoYGrbnQI_GMqHt6m0PSN9yA7Zvq7gA"></script>
<script
  src="https://cdn.socket.io/4.4.1/socket.io.min.js"
  integrity="sha384-fKnu0iswBIqkjxrhQCTZ7qlLHOFEgNkRmK2vaO/LbTZSXdJfAu6ewRBdwHPhBo/H"
  crossorigin="anonymous"
></script>
<script>
import ImageModal from "@/components/Modals/ImageModal.vue";
import SparksAreaCharts from "@/components/Charts/SparksAreaCharts.vue";

export default {
  props: ["vehiclesData"],

  data() {
    return {
      show: false,

      showActive: false,

      // vehiclesProfile: null,
      // Start:: Model Data
      model_1: {
        show_model: false,
        model_img_src: "",
      },
      randomColors: [],
      // End:: Model Data
    };
  },
  components: {
    ImageModal,
    SparksAreaCharts,
  },
  computed: {
    component() {
      return this.test ? "vue-ellipse-progress-test" : "vue-ellipse-progress";
    },
  },
  methods: {
    // Start:: Img Model
    show_model_1(src) {
      this.model_1.model_img_src = src;
      this.model_1.show_model = true;
    },
    // End:: Img Model
    randomizeOptions() {
      // this.progress = randomNumberInRange(0, 100);
      this.vehiclesData.orders_count = randomNumberInRange(0, 100);
    },

    toggleActive() {
      this.axios({
        method: "GET",
        url: `vehicles/${this.vehiclesData.id}/status`,
      })
        .then((res) => {
          console.log(res.data.data);
          this.$emit("updateData");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showActive = false;
        });
    },
    imageError() {
      this.vehiclesData.image = require("@/assets/media/images/car.gif");
    },
    mapLogic() {
      var position = [26.510519, 29.94873];
      // var socket = io("https://backend.captain1.app:3011"); // Test Baseurl
      var socket = io(process.env.VUE_APP_BASE_SOCKET_URL); // Live Baseurl
      let map;
      var markers = {};
      // Info window content

      var image = require("@/assets/media/images/mapCar.png");
      // socket.on("update_location", (data) => {
      socket.emit("join_update_location", {
        driver_id: this.vehiclesData.driver.id,
        dashboard: true,
      });
      // });

      socket.on("update_location_on_map", (data) => {
        console.log(data);
        if (!markers[data.driver_id]) {
          setMarker([data.lat, data.lng], data.driver_id);
        } else if (markers[data.driver_id]) {
          // transition([data.lat, data.lng] , data.driver_id) ;
          updateMap(
            {
              lat: data.lat,
              lng: data.lng,
            },
            data.driver_id
          );
        }
      });

      function initialize() {
        var latlng = new google.maps.LatLng(position[0], position[1]);
        console.log(latlng);
        var myOptions = {
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          zoom: 8,
          center: latlng,
          scrollwheel: false,
          scaleControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        };

        map = new google.maps.Map(
          document.getElementById("mapCanvas"),
          myOptions
        );
      }

      socket.on("disconnect_from_map", (data) => {
        removeMarker(data.driver_id);
      });

      // Load google map
      google.maps.event.addDomListener(window, "load", initialize);

      function updateMap(coordinates, marker_id) {
        if (findMarker(marker_id)) {
          findMarker(marker_id).setPosition(coordinates);
          findMarker(marker_id).setTitle(
            "lat:" + coordinates.lat + "lng:" + coordinates.lng
          );
        }
      }

      function setMarker(marker_position, markerId) {
        removeMarker(markerId);
        var temp_marker = new google.maps.Marker({
          position: new google.maps.LatLng(
            marker_position[0],
            marker_position[1]
          ),

          icon: image,
          title: "Uluru (Ayers Rock)",
        });

        temp_marker.setMap(map);
        temp_marker.metadata = {
          id: markerId,
        };
        markers[markerId] = temp_marker;

        const contentString = `<div class="showDetails"><a target="_blank" href="/drivers/show/${markerId}">Show details</a></div>`;

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });

        temp_marker.addListener("click", () => {
          infowindow.open({
            anchor: temp_marker,
            map,
            shouldFocus: false,
          });
        });
      }

      function removeMarker(markerId) {
        if (markers[markerId]) {
          markers[markerId].setMap(null);
          delete markers[markerId];
        }
      }

      function findMarker(markerId) {
        if (markers[markerId]) {
          // markers[markerId].setMap(null);
          // delete markers[markerId];
          return markers[markerId];
        }
      }
    },
    changeActiveStatus() {
      this.axios
        .post(`vehicle/${this.vehiclesData.id}/update-is_active`)
        .then(() =>
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message: this.$t("editSuccess"),
          })
        )
        .catch((err) =>
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          })
        );
    },
    changeLockStatus() {
      this.axios
        .post(`vehicle/${this.vehiclesData.id}/update-is_lock`)
        .then(() =>
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message: this.$t("editSuccess"),
          })
        )
        .catch((err) =>
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          })
        );
    },
    changeTripStatus() {
      this.axios
        .post(`vehicle/${this.vehiclesData.id}/update-is_in_order`)
        .then(() =>
          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message: this.$t("editSuccess"),
          })
        )
        .catch((err) =>
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          })
        );
    },
  },
  mounted() {
    // this.mapLogic();
  },
};
</script>

<style lang="scss">
.vehicle_show {
  .action {
    border-radius: 50%;
    display: block;
    width: 40px;
    height: 40px;
    background: var(--main);
    margin: 25px 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      margin: 0 !important;
      color: #fff !important;
    }
  }
  .vehicles_profile_card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    @include flexSpaceBetweenAlignment;
    align-items: flex-start;
    overflow: hidden;
    .row {
      margin-top: 0;
    }
    .vehicle_image {
      border-radius: 8px;
      overflow: hidden;
      margin-top: 20px;
      img {
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        max-height: 500px;
      }
    }
    .chart_progress {
      width: 70%;
    }
    .vehicle_info {
      background-color: #fbfbfe;
      border-radius: 10px;
      height: 100%;
      width: 30%;
      .map {
        background-image: url("@/assets/media/images/map.png");
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        margin-bottom: 1rem;
      }
      h5 {
        margin: 0 auto;
        margin-bottom: 20px;
        position: relative;
        text-align: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        &::before {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translate(-50%);
          display: block;
          width: 60%;
          height: 2px;
          border-radius: 15px;
          background-color: var(--mainText);
        }
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1rem;
        li {
          display: flex;
          margin-bottom: 0.6rem;
          font-size: 0.9rem;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #c4c7d7;
          p {
            margin: 0;
            font-weight: 100;
            margin-inline-start: 0.5rem;
            color: #1e2e45;
            font-weight: bold;
          }
          // .icon {
          //   width: 40px;
          //   height: 40px;
          //   border-radius: 8px;
          //   background-color: #fff;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   margin-inline-end: 1rem;
          //   color: #80c2ff;
          //   i {
          //     font-size: 1.5rem;
          //   }
          // }
        }
      }
    }
    .user_status {
      display: flex;
      align-items: center;
      .v-input .v-input__control .v-input__slot {
        padding: 0 !important;
        margin-inline: 5px;
      }
      font-size: 14px;
    }
    .ep-legend--value {
      @include flexCenterAlignment;
    }
    .chart_car {
      @include flexCenterAlignment;
      margin-block: 2rem;
      .icons {
        color: var(--main);
        @include flexCenterAlignment;
        flex-direction: column;
      }
    }
    .ep-container:nth-child(2) {
      margin-inline: 2rem;
    }
    .chart_progress {
      padding: 1rem;
      .car_name {
        font-size: 1.1rem;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        display: flex;
        // .carType {
        //   position: absolute;
        //   left: 0;
        // }
        // .carActive {
        //   position: absolute;
        //   left: 100px;
        // }
        span {
          margin-inline-start: 0.8rem;
        }
        color: #a0a5bd;
      }
      .image_wrapper {
        width: 100%;
      }
    }
  }
  .chart_side_page {
    background-color: #fff;
    border-radius: 15px;
    padding: 1rem;
    .chart_card {
      height: 150px;
    }
  }
  .chart_side_count {
    height: 400px;
    position: relative;
    overflow-x: scroll;
  }
  .chart_side_page {
    h3 {
      font-size: 17px;
      margin-bottom: 2rem;
      padding: 10px;
      padding-bottom: 0;
      padding-inline-start: 0;
      color: var(--mainText);
    }
    ul {
      @include flexSpaceBetweenAlignment;
      flex-wrap: wrap;
      li {
        border-inline-start: 2px solid var(--main);
        width: 50%;
        margin-bottom: 1rem;
        padding-inline-start: 0.5rem;
        h6 {
          color: var(--main);
          font-size: 0.9rem;
        }
        p {
          margin: 0;
          font-size: 0.8rem;
          color: #a0a5bd;
        }
      }
    }
  }

  .ep-container {
    .icons {
      color: #a1a6be !important;
      small {
        font-weight: bold;
        margin-top: 0.5rem !important;
        margin-bottom: 0.1rem !important;
      }
    }
    .text {
      color: #a1a6be;
    }
    // &:nth-child(2) {
    //   .icons {
    //     small {
    //       color: #80c2ff !important;
    //     }
    //   }
    // }
    // &:nth-child(3) {
    //   .icons {
    //     small {
    //       color: crimson !important;
    //     }
    //   }
    // }
  }

  @media (max-width: 850px) {
    .vehicles_profile_card {
      flex-direction: column;
      .chart_car {
        margin-block: 2rem 0.8rem;
        flex-wrap: wrap;
        row-gap: 15px;
      }
      .chart_progress {
        width: 100%;
        .car_name {
          span {
            margin-inline-start: 0;
          }
        }
      }
      .vehicle_info {
        border-radius: 0;
        width: 100%;
        .map {
          height: 200px;
          margin-bottom: 0.7rem;
        }

        ul {
          li {
            justify-content: space-between;
          }
        }
      }
    }
    .chart_side_count {
      height: unset;
    }
    .chart_side_page {
      ul {
        flex-direction: column;
        row-gap: 12px;
        li {
          width: 100%;
        }
      }
    }
    .ep-container {
      width: 40% !important;
    }
  }

  @media (max-width: 1100px) {
    .vehicles_profile_card {
      flex-direction: column;
      .chart_progress {
        width: 100%;
        .car_name {
          row-gap: 10px;
          .carType {
            top: 0;
            left: 0;
          }
          .carActive {
            top: 0;
            left: 100px;
          }
          span {
            margin-inline-start: 0;
          }
        }
      }
      .vehicle_info {
        border-radius: 0;
        width: 100%;
        .map {
          height: 200px;
          margin-bottom: 0.7rem;
        }

        ul {
          li {
            justify-content: space-between;
          }
        }
      }
    }
    .chart_side_count {
      height: unset;
    }
  }
}
</style>
