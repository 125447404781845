<template>
  <Loader v-if="loading"></Loader>
  <div v-else>
    <Breadcrumb :items="items" />
    <noData v-if="noData" />
    <div class="form countryForm custom_card fadeIn" v-else>
      <ValidationObserver ref="formVehicles" @submit.prevent="submit">
        <div class="row">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('labels.vehicleImage')"
            class="d-block my-auto"
            style="width: 100%;"
            :rules="editMode ? '' : 'required'"
          >
            <!-- @change="sentImage($event)" -->
            <v-file-input
              :disabled="btnLoading"
              :loading="btnLoading"
              @change="uploadImage($event, 'vehicles', 'avatar')"
              v-model="data.avatar.mainMedia"
              class="d-none"
              :name="$t('labels.vehicleImage')"
              accept="image/*,|size:2048"
              label=""
              id="banner-create-image-main"
            ></v-file-input>
            <label
              :class="errors[0] ? 'errors' : ''"
              for="banner-create-image-main"
              class="upload-image-label d-block d-flex justify-content-center align-items-center mx-auto text-center border rounded-xl overflow-hidden"
            >
              <v-img
                v-if="data.avatar.preview"
                class="d-flex"
                cover
                :src="data.avatar.preview"
                :aspect-ratio="3.4"
              />
              <img
                v-else
                class="d-flex img-fluid mx-auto"
                src="@/assets/media/images/placeholder.png"
              />

              <span class="error--text d-block">
                {{ errors[0] }}
              </span>
            </label>
          </validation-provider>
          <!-- Start partner -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.partner')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <base-select-input
              :classes="errors[0] ? 'error' : ''"
              :loading="loadingPartner"
              v-model="data.partner_id"
              :static="true"
              :name="$t('labels.partner')"
              :staticItems="partners"
              :placeholder="$t('labels.partner')"
            ></base-select-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start partner -->
          <!-- Start package -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.package')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <base-select-input
              :classes="errors[0] ? 'error' : ''"
              :loading="loadingPackage"
              v-model="data.vehicle_package_id"
              :static="true"
              :staticItems="packages"
              :placeholder="$t('labels.package')"
              :name="$t('labels.package')"
            ></base-select-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start package -->
          <!-- Start model -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.brand')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <base-select-input
              :classes="errors[0] ? 'error' : ''"
              :loading="loadingBrand"
              v-model="data.vehicle_brand_id"
              :static="true"
              :staticItems="brands"
              @input="getModels(data.vehicle_brand_id)"
              :placeholder="$t('labels.brand')"
              :name="$t('labels.brand')"
            ></base-select-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start model -->
          <!-- Start model -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.model')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <base-select-input
              :classes="errors[0] ? 'error' : ''"
              :loading="loadingModel"
              v-model="data.vehicle_model_id"
              :static="true"
              :staticItems="models"
              :placeholder="$t('labels.model')"
              :name="$t('labels.model')"
            ></base-select-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start model -->
          <!-- Start manufactruing_year -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.manufactruing_year')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <base-select-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.manufacturing_year"
              :staticItems="years"
              :placeholder="$t('labels.manufactruing_year')"
              :loading="loadingModel"
              :static="true"
              :name="$t('labels.manufactruing_year')"
            ></base-select-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start manufactruing_year -->
          <!-- Start color -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.color')"
            class="col-md-6 col-lg-4"
            rules="required|min:2"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.color"
              :placeholder="$t('labels.color')"
              type="text"
              :name="$t('labels.color')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start color -->
          <!-- Start seats_number -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.seats_number')"
            class="col-md-6 col-lg-4"
            rules="required|min_value:0"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.seats_number"
              :placeholder="$t('labels.seats_number')"
              type="number"
              @keypress="preventMinus"
              :name="$t('labels.seats_number')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start seats_number -->
          <!-- Start license_number -->
          <!-- <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.license_number')"
            class="col-md-6 col-lg-4"
            rules="required|min:2|min_value:0"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.license_number"
              :placeholder="$t('labels.license_number')"
              type="number"
              @keypress="preventMinus"
              :name="$t('labels.license_number')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider> -->
          <!-- Start license_number -->
          <!-- Start license_serial_number -->
          <!-- <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.license_serial_number')"
            class="col-md-6 col-lg-4"
            rules="required|min:2|min_value:0"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.license_serial_number"
              :placeholder="$t('labels.license_serial_number')"
              type="number"
              @keypress="preventMinus"
              :name="$t('labels.license_serial_number')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider> -->
          <!-- Start license_serial_number -->
          <!-- Start plate_number -->
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.plate_numbers')"
            class="col-md-6 col-lg-4"
            rules="min:2|min_value:0"
          >
            <base-input
              :classes="errors[0] ? 'error' : ''"
              v-model="data.plate_number"
              :placeholder="$t('labels.plate_numbers')"
              type="number"
              @keypress="preventMinus"
              :name="$t('labels.plate_numbers')"
            ></base-input>

            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <!-- Start plate_number -->

          <!-- Start :: vehicle_requirements -->
          <!-- <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.vehicle_requirements") }}</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div> -->
          <!-- End :: vehicle_requirements -->
          <!-- Start :: status -->

          <!-- <ValidationProvider
            v-for="(requirement, index) in data.vehicle_requirements"
            :key="requirement.id"
            v-slot="{ errors }"
            :name="requirement.name"
            class="col-xl-6"
            rules="required"
          >
            <div class="d-flex align-items-center vehicle_requirement">
              <div class="input-title">
                <h4>{{ requirement.name }}</h4>
              </div>
              <v-radio-group v-model="data.vehicle_requirements[index].status">
                <v-radio
                  :classes="errors[0] ? 'error' : ''"
                  v-for="status in statusList"
                  :key="status.id"
                  :label="status.name"
                  :value="status.id"
                  :name="requirement.name"
                ></v-radio>
              </v-radio-group>
            </div>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider> -->

          <!-- Start :: status -->

          <!-- Start :: Checks -->
          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.status") }}</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>
          <!-- End :: Checks -->
          <!-- Start :: status -->

          <!-- <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.status')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <div>
              <div class="input-title">
                <h4>{{ $t("labels.status") }}</h4>
              </div>
              <v-radio-group
                :classes="errors[0] ? 'error' : ''"
                v-model="data.status"
                :name="$t('labels.status')"
              >
                <v-radio
                  v-for="status in statusList"
                  :key="status.id"
                  :label="status.name"
                  :value="status.id"
                ></v-radio>
              </v-radio-group>
            </div>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider> -->

          <!-- Start :: status -->

          <!-- Start :: is_active -->

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.is_active')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <div>
              <div class="input-title">
                <h4>{{ $t("active", { name: $t("labels.vehicle") }) }}</h4>
              </div>
              <v-checkbox
                :classes="errors[0] ? 'error' : ''"
                :name="$t('labels.is_active')"
                class="py-0 px-0"
                v-model="data.is_active"
                :label="$t('labels.is_active')"
              ></v-checkbox>
            </div>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <!-- Start :: is_active -->
          <!-- Start :: is_in_order -->

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('labels.is_in_order')"
            class="col-md-6 col-lg-4"
            rules="required"
          >
            <div>
              <div class="input-title">
                <h4>{{ $t("labels.is_in_order") }}</h4>
              </div>
              <v-checkbox
                :classes="errors[0] ? 'error' : ''"
                :name="$t('labels.is_in_order')"
                class="py-0 px-0"
                v-model="data.is_in_order"
                :label="$t('labels.is_in_order')"
              ></v-checkbox>
            </div>
            <span v-if="errors[0]" class="error--text d-inline-block">
              {{ errors[0] }}
            </span>
          </ValidationProvider>

          <!-- Start :: is_in_order -->

          <!-- Start :: vehicle_requirements -->
          <div class="col-lg-12 py-0">
            <div class="divider">
              <h4>{{ $t("labels.location") }}</h4>
              <hr
                role="separator"
                aria-orientation="horizontal"
                class="v-divider theme--dark"
              />
            </div>
          </div>
          <!-- End :: vehicle_requirements -->
        </div>
        <div class="col-12">
          <select-map :biggingMarkers="center" @makerChanged="makerChanged" />
        </div>
        <div class="button_wrapper center">
          <v-btn
            type="submit"
            @click="submit"
            :disabled="btnLoading"
            :loading="btnLoading"
          >
            {{ $t("submit") }}
          </v-btn>
        </div>
        <!-- <base-button type="submit" @click="submit" class="center"></base-button> -->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import BaseCountryFlagPhoneInput from "@/components/Forms/BaseCountryFlagPhoneInput.vue";

export default {
  components: { BaseCountryFlagPhoneInput },
  props: ["id"],

  data() {
    return {
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.vehicles.title"),
          disabled: false,
          href: "/vehicles/show-all",
        },
        {
          text: this.$t(`breadcrumb.vehicles.${this.id ? "edit" : "add"}`),
          disabled: true,
          href: "",
        },
      ],
      // Btn Loading
      loading: false,
      btnLoading: false,
      loadingPartner: false,
      loadingPackage: false,
      loadingModel: false,
      loadingBrand: false,
      data: {
        avatar: {
          mainMedia: null,
          preview: null,
          image: null,
          mainPreviewUrl: null,
        },
        vehicle_model_id: null,
        vehicle_package_id: null,
        vehicle_brand_id: null,
        partner_id: null,
        manufacturing_year: null,
        color: null,
        license_number: null,
        license_serial_number: null,
        plate_number: null,
        seats_number: null,
        lat: null,
        lng: null,
        status: null,
        is_active: false,
        is_in_order: false,
        vehicle_requirements: [],
      },
      noData: false,
      center: null,
      // Fixed Data
      partners: [],
      packages: [],
      years: [],
      brands: [],
      models: [],
      vehicle_requirements: [],
      statusList: [
        {
          id: "according",
          name: this.$t("according"),
        },
        {
          id: "not_according",
          name: this.$t("not_according"),
        },
      ],
    };
  },

  methods: {
    manufactruingYears() {
      let max = new Date().getFullYear();
      let min = max - 30;
      let years = [];
      for (let i = max; i >= min; i--) {
        years.push({ id: i, name: i });
      }
      this.years = years;
    },
    // Main Image

    // Partners
    getPartners() {
      this.loadingPartner = true;
      this.axios({
        method: "GET",
        url: `partners_not_paginated`,
      })
        .then((res) => {
          this.partners = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.full_name,
            };
          });
        })
        .catch((err) => {
          console.log(err);
          this.loadingPartner = false;
          this.items = [
            { id: 1, name: "Item Default 1" },
            { id: 2, name: "Item Default 2" },
          ];
        })
        .finally(() => {
          this.loadingPartner = false;
        });
    },
    // Packages
    getPackages() {
      this.loadingPackage = true;
      this.axios({
        method: "GET",
        url: `vehicle_package_not_paginated`,
      })
        .then((res) => {
          this.packages = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.ar?.title ?? item.title,
            };
          });

          this.loadingPackage = false;
        })
        .catch((err) => {
          this.loadingPackage = false;
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        });
    },
    // Models
    getBrands() {
      this.loadingBrand = true;
      this.axios({
        method: "GET",
        url: `vehicle_brand_not_paginated`,
      })
        .then((res) => {
          this.brands = res.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          this.loadingBrand = false;
        })
        .catch((err) => {
          this.loadingBrand = false;

          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        })
        .finally(() => {
          this.loadingBrand = false;
        });
    },
    // Models
    getModels(e) {
      console.log(e);
      this.loadingModel = true;
      this.axios({
        method: "GET",
        url: `vehicle_model_not_paginated?brand_id=${e.id}`,
      })
        .then((res) => {
          this.models = res.data.data.map((item) => {
            return {
              id: item.id,
              // name: item[this.$i18n.locale].name,
              name: item.name,
            };
          });
          this.loadingModel = false;
        })
        .catch((err) => {
          this.loadingModel = false;

          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
        })
        .finally(() => {
          this.loadingModel = false;
        });
    },
    // vehicle-requirement_not_paginated
    // getVehicleRequirements() {
    //   this.axios({
    //     method: "GET",
    //     url: `vehicle-requirement_not_paginated`,
    //   })
    //     .then((res) => {
    //       this.data.vehicle_requirements = res.data.data.map((item) => {
    //         return {
    //           id: item.id,
    //           name: item[this.$i18n.locale].title,
    //           status: null,
    //         };
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // Main Edit Data
    getEditData() {
      this.noData = false;
      this.loading = true;
      this.axios({
        method: "GET",
        url: `vehicle/${this.id}`,
      })
        .then((res) => {
          const result = res.data.data;
          this.data.partner_id = result.partner_id;
          this.data.vehicle_package_id = result.vehicle_package_id;
          this.data.vehicle_brand_id = result.vehicle_model_id.vechile_brand;
          this.getModels(this.data.vehicle_brand_id);
          this.data.vehicle_model_id = result.vehicle_model_id;

          this.data.manufacturing_year = this.years.find(
            (el) => el.id == result.manufacturing_year
          );
          this.data.color = result.color;
          // this.data.license_number = result.license_number;
          // this.data.license_serial_number = result.license_serial_number;
          this.data.plate_number = result.plate_number;
          this.data.seats_number = result.seats_number;

          this.center = {
            lat: +result.lat,
            lng: +result.lng,
          };

          // this.data.status = result.status;
          this.data.is_in_order = result.is_in_order;
          this.data.is_active = result.is_active;

          this.data.avatar.preview = result.image;

          // result.vehicle_requirement_id.map((item) => {
          //   let found = this.data.vehicle_requirements.find(
          //     (el) => el.id == item.vehicle_requirement?.id
          //   );
          //   if (found) {
          //     found.status = item.status;
          //   }
          // });

          this.loading = false;
          this.data.lat = result.lat;
          this.data.lng = result.lng;
        })
        .catch((err) => {
          this.$iziToast.error({
            title: this.$t("error"),
            message: err.response?.data.message ?? err.response?.data.messages,
          });
          this.noData = true;
          this.loading = false;
        });
    },

    // Submit
    submit() {
      this.$refs.formVehicles.validate().then((d) => {
        if (d) {
          this.btnLoading = true;

          // Create FormData
          const data = new FormData();

          if (this.data.avatar.image) {
            data.append("image", this.data.avatar.image);
          }

          data.append("vehicle_model_id", this.data.vehicle_model_id.id);
          data.append("vehicle_package_id", this.data.vehicle_package_id.id);
          data.append("partner_id", this.data.partner_id.id);
          data.append("manufacturing_year", this.data.manufacturing_year.id);
          data.append("color", this.data.color);
          // data.append("license_number", this.data.license_number);
          // data.append("license_serial_number", this.data.license_serial_number);
          data.append("plate_number", this.data.plate_number);
          data.append("seats_number", this.data.seats_number);
          data.append("lat", this.data.lat);
          data.append("lng", this.data.lng);
          // data.append("status", this.data.status);
          data.append("is_active", +this.data.is_active);
          data.append("is_in_order", +this.data.is_in_order);
          // this.data.vehicle_requirements.map((el, index) => {
          //   data.append(
          //     `vehicle_requirement_ids[${index}][requirement_id]`,
          //     el.id
          //   );
          //   data.append(`vehicle_requirement_ids[${index}][status]`, el.status);
          // });

          if (this.id) {
            data.append("_method", "PUT");
          }
          let url = "";
          if (this.id) {
            url = `vehicle/${this.id}`;
          } else {
            url = "vehicle";
          }

          let message = this.id
            ? this.$t("editSuccess")
            : this.$t("addSuccess");
          this.axios({
            method: "POST",
            url: url,
            data: data,
          })
            .then(() => {
              this.$iziToast.success({
                title: this.$t("success"),
                message: message,
              });
              this.$router.push("/vehicles/show-all");
              this.btnLoading = false;
            })
            .catch((err) => {
              this.$iziToast.error({
                title: this.$t("error"),
                message: err.response.data.message,
              });
              this.btnLoading = false;
            });

          // =============== End:: Add ===============
        } else {
          // for (const value of Object.entries(this.$refs.formVehicles.errors)) {
          //   if (value[1].length) {
          //     this.$iziToast.error({
          //       timeout: 2000,
          //       message: value[1],
          //       position: "bottomRight",
          //     });
          //   }
          // }
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    },
    uploadImage(event, modal, varName) {
      if (event) {
        this.btnLoading = true;
        const data = new FormData();
        data.append("file", event);
        data.append("attachment_type", "image");
        data.append("model", modal);

        this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASE_ATTACHMENT}/attachments`,
          data: data,
        })
          .then((res) => {
            this.data[varName].image = res.data.data;
            this.data[varName].preview = URL.createObjectURL(event);
            this.btnLoading = false;
          })
          .catch((err) => {
            this.$iziToast.error({
              title: this.$t("validation.error"),
              message: err.response.data.message,
            });

            this.btnLoading = false;
          });
      }
    },

    makerChanged(e) {
      this.data.lat = e.lat;
      this.data.lng = e.lng;
    },
  },

  computed: {
    editMode() {
      return !!this.id;
    },
  },
  // async mounted() {
  //   this.getPartners();
  //   this.getPackages();
  //   this.getModels();
  //   this.manufactruingYears();
  //   await this.getVehicleRequirements();
  //   if (this.editMode) {
  //     this.getEditData();
  //   }
  // },
  async created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.getPartners();
    this.getPackages();
    this.getBrands();
    this.manufactruingYears();
    // await this.getVehicleRequirements();
    if (this.editMode) {
      this.getEditData();
    }
  },
};
</script>

<style lang="scss">
.v-input--radio-group--column {
  .v-input--radio-group__input {
    flex-direction: row !important;
  }
}
.v-input--selection-controls.v-input {
  margin-bottom: 0 !important;
  .v-messages {
    display: none !important;
  }
}
// .v-input--radio-group.v-input .v-input__control .v-input__slot {
//   padding-top: 0 !important;
// }
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
.vehicle_requirement {
  margin-top: 10px;

  padding: 6px;

  .input-title {
    min-width: 200px;
    padding-top: 15px !important;
    h4 {
      margin: 0 !important;
    }
  }
}
</style>
