<template>
  <div class="countries_all">
    <the-table
      :headers="headers"
      tableName="price_offers"
      @expandFired="mapItem"
      :showFilter="false"
    >
      <!-- <template v-slot:expand>
        <ul>
          <li v-for="(value, key) of expandData" :key="key">
            <span class="key">{{ key }}</span>
            <span class="value">
              <template v-if="key.includes('الوصف')" v-html="value"> </template>
              <template v-else-if="value">{{ value }}</template>
              <template v-else>{{ $t("table.noData") }}</template>
            </span>
          </li>
        </ul>
      </template> -->
    </the-table>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      // ========== headers
      headers: [
        {
          text: this.$t("labels.mainImage"),
          align: "center",
          value: "image",
          sortable: false,
        },
        {
          text: "الكميه",
          value: "amountOffer",
          align: "center",
        },
        {
          text: "النقاط",
          value: "points",
          align: "center",
        },
        {
          text: this.$t("labels.created_at"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("labels.start_at"),
          value: "start_at",
          align: "center",
        },
        {
          text: this.$t("labels.end_at"),
          value: "end_at",
          align: "center",
        },
        {
          text: "الوصف عربي",
          value: "descAr",
          align: "center",
        },
        {
          text: "الوصف انجليزي",
          value: "descEn",
          align: "center",
        },
        {
          text: "المزيد",
          value: "extendIcon",
          align: "center",
          sortable: false,
        },

        {
          text: this.$t("labels.control"),
          value: "actionsWithoutshow",
          align: "center",
          sortable: false,
        },
      ],
      expandData: null,
    };
  },

  methods: {
    ...mapMutations({
      set_breadcrumb: "set_breadcrumb",
    }),

    mapItem(data) {
      this.expandData = {
        ["الإسم عربي"]: data.ar.name || "لايوجد",
        ["الإسم EN"]: data.en.name || "لايوجد",
        [this.$t("labels.is_active")]: this.helper_boolToText(data.is_active),
        [this.$t("labels.is_trip_active")]: this.helper_boolToText(
          data.is_trip_active
        ),
        [this.$t("labels.is_city_to_city_active")]: this.helper_boolToText(
          data.is_city_to_city_active
        ),
        [this.$t(
          "labels.is_package_delivering_active"
        )]: this.helper_boolToText(data.is_package_delivering_active),
      };
    },
  },

  created() {
    this.control = this.$permission.setControls(this.$route.path);
    this.set_breadcrumb({
      title: this.$t("breadcrumb.price_offers.title"),
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.price_offers.title"),
          disabled: false,
          href: "/price_offers",
        },
        {
          text: this.$t("breadcrumb.price_offers.all"),
          disabled: true,
          href: "",
        },
      ],
    });
  },
};
</script>
